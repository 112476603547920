import { ref,reactive,toRefs,onMounted,computed,watch,getCurrentInstance,nextTick,defineComponent} from 'vue';

export default defineComponent({
    name: "LinkComponent",
    props: {
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const appInstant = getCurrentInstance();
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const engineParams=props.engineParams as any;
        let dataObj=reactive({
            linkUrl:engineParams.linkUrl
        })
        onMounted(async ()=>{
            // console.log(engineParams);
        })
        return {
            ...toRefs(dataObj)
        }
    }
})